<template>
  <div>
    <v-row>
      <v-col cols="12">
        <router-link :to="{ name: 'addNews' }">
          <v-btn color="primary">{{ $t('news.add_news') }}</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!--     <data-table-with-pagination
          :headers="news_header"
          :dataList="news_list"
          :loading="load_table"
        ></data-table-with-pagination> -->
        <CorrectDataTable
          :table-headers="news_header"
          :table-data="news_list"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="total_items"
          :is-loading="load_table"
          :enable-search="false"
          :enable-filter="false"
          :enable-date-range="false"
          :enable-clear-all="false"
          :disable-pagination="load_table"
          :disable-sort="true"
          :actionViewRouteLink="actionViewRouteLink"
          @options-update="onTableChange"
        ></CorrectDataTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CorrectDataTable from '@/components/CorrectDataTable.vue'
import { getData } from '@/api'
export default {
  name: 'News',
  components: {
    CorrectDataTable,
  },
  data: () => ({
    news_header: [],
    news_list: [],
    load_table: true,
    tablePage: 1,
    tablePageLimit: 10,
    tablePageLimitOptions: [10, 20, 50],
    total_items: 0,
    actionViewRouteLink: 'NewsDetail',
  }),
  methods: {
    initializeHeader() {
      this.news_header = [
        {
          text: this.$t('news.news_date'),
          value: 'news_date',
        },
        {
          text: this.$t('news.image'),
          value: 'news_image',
        },
        {
          text: this.$t('news.title_zh'),
          value: 'news_title_zh',
        },
        {
          text: this.$t('news.title_en'),
          value: 'news_title_en',
        },
        {
          text: this.$t('worker.status'),
          value: 'status',
        },
        {
          text: '',
          value: 'actionView',
        },
      ]
    },
    async initializeData() {
      this.load_table = true
      try {
        let send_data = { page: this.tablePage - 1, limit: this.tablePageLimit }
        let params = new URLSearchParams()
        params.set('cms_get_news', JSON.stringify(send_data))
        let get_news_data = await getData(params)
        this.$func.log('-----Get News Data-----')
        this.$func.log(get_news_data)

        if (!Array.isArray(get_news_data)) {
          this.total_items = get_news_data.total

          let news = get_news_data['data']

          let temp = []
          for (let i = 0; i < news.length; i++) {
            temp.push({
              news_date: news[i].news_date,
              id: news[i].id,
              news_image: news[i].banner.en,
              news_title_zh: news[i].title.zh,
              news_title_en: news[i].title.en,
              status: news[i].status,
            })
          }

          this.news_list = temp
        }
        this.load_table = false
      } catch (error) {
        this.$func.log(error)
        this.load_table = false
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }
      if (options.sortBy.length > 0) {
        if (options.sortBy[0].indexOf('user_data.') === 0) {
          this.tableSortKey = options.sortBy[0].substring(10)
        } else {
          this.tableSortKey = options.sortBy[0]
        }
      }

      this.tablePageLimit = options.itemsPerPage
      this.initializeData()
    },
  },
  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      this.initializeHeader()
      this.initializeData()
    }
  },
}
</script>